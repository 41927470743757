import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This writing guideline is the general writing rule that can be used and implemented for all digital products at Telkom. We standardized writing principles and patterns to maintain consistency in our language and style, and keep us connected to our users.`}</p>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Guideline Usage`}</h2>
    <ul>
      <li parentName="ul">{`Read and familiarize yourself with the rules.`}</li>
      <li parentName="ul">{`Implement the best suited rules on your products’ voice and tone.`}</li>
      <li parentName="ul">{`Give feedback if there’s something lacking`}</li>
    </ul>
    <p>{`Along with the contribution, feedback, and usage of this writing guideline from designers and UX writers, this guideline will be updated from time to time, just like our design system.`}</p>
    <div style={{
      "heigh": "1px",
      "marginBottom": "4rem"
    }} />
    <h2>{`Writing Principles`}</h2>
    <p>{`Communication is based on words. If we want to create a digital product that sounds human and relates to the people using it, we need communicative words. Words, in this context, complement our design and convey messages.`}</p>
    <p>{`Hence, we need to keep in mind these following principles:`}</p>
    <h3>{`Be clear`}</h3>
    <p>{`Use simple and easy to understand language. Try our best to use words that are familiar to our users and remove the unnecessary. With clear writing, it will take less cognitive effort, lower the memory’s burden, avoid misunderstanding and improve task success of our users.`}</p>
    <h3>{`Speak like a human`}</h3>
    <p>{`Our users are real people, not computers. Write in a way like we do in face-to-face conversations, adapt our tone depending on who we’re writing to and what we’re writing about. Words that we use in our content should reduce the alienation between people and machines. Write in a spoken language and use contractions to make the sentence flow more naturally.`}</p>
    <h3>{`Be helpful`}</h3>
    <p>{`We should put ourselves in the user’s shoes and only focus on content that helps the journey of users through our products. Be empathetic. We need to provide clear directions, unnecessary content makes it just harder for users to get things done.`}</p>
    <h3>{`Editing Phase`}</h3>
    <p>{`When we completed our first draft or current copy, editing is the most important part of all. Through editing, we aim to iterate the ‘technical’ of the copy to make sure that it meets its purposes — to be clear, conversational, and concise.`}</p>
    <p>{`Editing is a part of processes, not the end. So, there are several steps to achieve our end goal in the editing phase.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Make sure our first draft of the copy is already purposeful, although the copy could be far too long in words at first. In order to understand whether our copy has already catered what the experience is like for users.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Then, we can proceed to cut some words to make it concise because nobody came to our product to read a long text. Moreover, there is a limit of usable space for text in the experience. People find it easiest to scan text when it is 40 or fewer characters wide, and three or fewer lines long.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After it’s concise, we can adjust its conversational tone, and make sure the copy does not sound like a robot. When more than one phrase appears on the same screen, read them out loud together. For example, when there is a title, a heading, and the descriptions, try to read them altogether. They should feel and sound like a seamless and appropriate statement. As well as the copy for buttons, links, and other input options, they should feel like appropriate responses from the users to the experience.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ultimately, before we wrap up the copy crafting process, check the clarity once again. We can walk back through to the purpose, re-imagining the situations when the user encounters the copy. Make sure the words we use are the ones that the users will recognize easily.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Last, but not least. Check our consistency of word utilization throughout the experience to maintain trust and avoid confusion.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      